
function About() {
    return (
        <div className="container">
        <div className="row">
            <div className="col-lg-5">
                <div className="faq-content mt-45">
                    <div className="about-title">
                        <h6 className="sub-title">A Little More About Us</h6>
                        <h4 className="title">Frequently Asked Questions <br /> About Vesta services</h4>
                    </div> {/*  faq title  */}
                    <div className="about-accordion">
                        <div className="accordion" id="accordionExample">
                            <div className="card">
                                <div className="card-header" id="headingOne">
                                    <a href="#" data-toggle="collapse" data-target="#collapseOne"
                                        aria-expanded="true" aria-controls="collapseOne">Can I track assets other than vehicles?</a>
                                </div>

                                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne"
                                    data-parent="#accordionExample">
                                    <div className="card-body">
                                        <p className="text">Yes, we have a variety of portable GPS devices that can track assets like personal belongings, equipments, raw materials, products etc.</p>
                                    </div>
                                </div>
                            </div> {/*  card  */}
                            <div className="card">
                                <div className="card-header" id="headingFive">
                                    <a href="#" className="collapsed" data-toggle="collapse" data-target="#collapseFive"
                                        aria-expanded="false" aria-controls="collapseFive">How do I purchase Vesta's tracking services for my asset(s) or vehicle(s)? </a>
                                </div>
                                <div id="collapseFive" className="collapse" aria-labelledby="headingFive"
                                    data-parent="#accordionExample">
                                    <div className="card-body">
                                        <p className="text">You can reach out to us via email, live chat, social media channels (whatsapp, instagram, twitter or linkedin). Or you can leave a message directly to us at the <a className="page-scroll" href="#contact">Contact</a> section of our website. Once we complete initial assessment of your needs we will send you an invoice for payment while our technicians begin installtion. <br/> <br/> After installation we will register an account for you on our systems where you can begin tracking your assets/objects either by web or using our mobile apps available in iOS and Android. </p>
                                    </div>
                                </div>
                            </div> {/*  card  */}
                            <div className="card">
                                <div className="card-header" id="headingTwo">
                                    <a href="#" className="collapsed" data-toggle="collapse" data-target="#collapseTwo"
                                        aria-expanded="false" aria-controls="collapseTwo">What happens if a device go offline?</a>
                                </div>
                                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo"
                                    data-parent="#accordionExample">
                                    <div className="card-body">
                                        <p className="text">If you're unable to track your assets/objects kindly contact us via email, live chat or on any of our social media channels listed at the bottom of this page. Do not attempt to open or disconnect the device as it will violate our tampering policy.</p>
                                    </div>
                                </div>
                            </div> {/*  card  */}
                            <div className="card">
                                <div className="card-header" id="headingThree">
                                    <a href="#" className="collapsed" data-toggle="collapse"
                                        data-target="#collapseThree" aria-expanded="false"
                                        aria-controls="collapseThree">How do I make payments?</a>
                                </div>
                                <div id="collapseThree" className="collapse" aria-labelledby="headingThree"
                                    data-parent="#accordionExample">
                                    <div className="card-body">
                                        <p className="text"> We do accept payments via cash, mobile and bank. Whenever you have any due payments you will receive an invoice via registered contacts. Invoices usually contain all necessary information to complete payments. </p>
                                    </div>
                                </div>
                            </div> {/*  card  */}
                            <div className="card">
                                <div className="card-header" id="headingFore">
                                    <a href="#" className="collapsed" data-toggle="collapse" data-target="#collapseFore"
                                        aria-expanded="false" aria-controls="collapseFore">Do I get charged extra if a device is damaged?</a>
                                </div>
                                <div id="collapseFore" className="collapse" aria-labelledby="headingFore"
                                    data-parent="#accordionExample">
                                    <div className="card-body">
                                        <p className="text">If a device is a rental and was not tampered with then we will replace it at no extra cost. It is important to not tamper with any device(s) installed on your assets, vehicles or other objects to avoid losing a free replacement in this scenario. </p>
                                    </div>
                                </div>
                            </div> {/*  card  */}
                        </div>
                    </div> {/*  faq accordion  */}
                </div> {/*  faq content  */}
            </div>
            <div className="col-lg-7">
                <div className="about-image mt-50">
                    <img src="assets/images/about.png" alt="about" />
                </div> {/*  faq image  */}
            </div>
        </div> {/*  row  */}
    </div> 
);
}
export default About;