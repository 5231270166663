
function Team() {
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-12 col-md-10">
                    <div className="section-title text-center pb-30">
                        <h3 className="title">Meet The Team</h3>
                        <p className="text">We present to you <b>VESTANIANS</b>! This team is loaded with rockstars equipped with unique talent, passion, experience and are dedicated to deliver affordable, cutting edge sulutions to you. </p>
                    </div> {/*  section title  */}
                </div>
            </div> {/*  row  */}
            <div className="row justify-content-center">
                <div className="col-lg-4 col-sm-6">
                    <div className="team-style-eleven text-center mt-30 wow fadeIn" data-wow-duration="1s"
                        data-wow-delay="0s">
                        <div className="team-image">
                            <img src="assets/images/team-4.jpg" alt="Team" />
                        </div>
                        <div className="team-content">
                            <div className="team-social">
                                <ul className="social">
                                    <li><a href="https://mobile.twitter.com/gossoronald" target="_blank"><i className="lni lni-twitter-original"></i></a></li>
                                    <li><a href="https://tz.linkedin.com/in/ronald-isack-gosso?original_referer=https%3A%2F%2Fwww.google.com%2F
PHub: —-" target="_blank"><i className="lni lni-linkedin-original"></i></a></li>
                                    <li><a href="https://instagram.com/ronald_isack_gosso?igshid=YWJhMjlhZTc=" target="_blank"><i className="lni lni-instagram"></i></a></li>
                                </ul>
                            </div>
                            <h4 className="team-name"><a href="#">Ronald Gosso</a></h4>
                            <span className="sub-title">Sr Software Engineer</span>
                        </div>
                    </div> {/*  single team  */}
                </div>
                <div className="col-lg-4 col-sm-6">
                    <div className="team-style-eleven text-center mt-30 wow fadeIn" data-wow-duration="1s"
                        data-wow-delay="0s">
                        <div className="team-image">
                            <img src="assets/images/team-1.jpg" alt="Team" />
                        </div>
                        <div className="team-content">
                            <div className="team-social">
                                <ul className="social">
                                    <li><a href="https://tz.linkedin.com/in/tunzo-bashari-msuya-77603633" target="_blank"><i className="lni lni-linkedin-original"></i></a></li>
                                </ul>
                            </div>
                            <h4 className="team-name"><a href="#">Tunzo Msuya</a></h4>
                            <span className="sub-title">Director of Innovations</span>
                        </div>
                    </div> {/*  single team  */}
                </div>
                <div className="col-lg-4 col-sm-6">
                    <div className="team-style-eleven text-center mt-30 wow fadeIn" data-wow-duration="1s"
                        data-wow-delay="0s">
                        <div className="team-image">
                            <img src="assets/images/team-5.jpg" alt="Team" />
                        </div>
                        <div className="team-content">
                            <h4 className="team-name"><a href="#">Desmond Natai</a></h4>
                            <span className="sub-title">Sr Hardware Technician</span>
                        </div>
                    </div> {/*  single team  */}
                </div>
                <div className="col-lg-4 col-sm-6">
                    <div className="team-style-eleven text-center mt-30 wow fadeIn" data-wow-duration="1s"
                        data-wow-delay="0s">
                        <div className="team-image">
                            <img src="assets/images/team-3.jpg" alt="Team" />
                        </div>
                        <div className="team-content">
                            <div className="team-social">
                                <ul className="social">
                                    <li><a href="https://www.facebook.com/hermzah.early?mibextid=LQQJ4d" target="_blank"><i className="lni lni-facebook-filled"></i></a></li>
                                    <li><a href="https://twitter.com/hamzed88" target="_blank"><i className="lni lni-twitter-original" ></i></a></li>
                                    <li><a href="https://instagram.com/hamz3d_?igshid=YWJhMjlhZTc=" target="_blank"><i className="lni lni-instagram" ></i></a></li>
                                    <li><a href="https://www.linkedin.com/in/hamzed88" target="_blank"><i className="lni lni-linkedin-original"></i></a></li>
                                </ul>
                            </div>
                            <h4 className="team-name"><a href="#">Hamza Mohammed</a></h4>
                            <span className="sub-title">Sr Software Engineer</span>
                        </div>
                    </div> {/*  single team  */}
                </div>
                <div className="col-lg-4 col-sm-6">
                    <div className="team-style-eleven text-center mt-30 wow fadeIn" data-wow-duration="1s"
                        data-wow-delay="0s">
                        <div className="team-image">
                            <img src="assets/images/team-2.jpg" alt="Team" />
                        </div>
                        <div className="team-content">
                            <div className="team-social">
                                <ul className="social">
                                    <li><a href="https://mobile.twitter.com/realdanielmoshi" target="_blank"><i className="lni lni-twitter-original"></i></a></li>
                                    <li><a href="https://www.linkedin.com/in/daniel-moshi-9828643b" target="_blank"><i className="lni lni-linkedin-original"></i></a></li>
                                    <li><a href="https://instagram.com/dmoshi?igshid=YmMyMTA2M2Y=" target="_blank"><i className="lni lni-instagram"></i></a></li>
                                </ul>
                            </div>
                            <h4 className="team-name"><a href="#">Daniel Moshi</a></h4>
                            <span className="sub-title">Director of Technology</span>
                        </div>
                    </div> {/*  single team  */}
                </div>

            </div> {/*  row  */}
        </div>
    );
}
export default Team;