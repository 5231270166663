import { HashLink } from "react-router-hash-link";
import { MdArrowBackIos, MdNotificationsActive, MdOutlineContactPhone } from 'react-icons/md';
import { FaGasPump, FaRoute } from "react-icons/fa";
import { TbReportSearch } from "react-icons/tb";
import { GiGasPump, GiMoneyStack } from "react-icons/gi";
import {TfiDashboard} from 'react-icons/tfi';
import { BsChatDots } from "react-icons/bs";

function MonitorFuel() {
    return (
        <div>

            <section id="fuel" className="features-area">
                <div className="container">
                    <HashLink to="/home#services" ><h1><MdArrowBackIos style={{ color: '#2B82D4' }} /> </h1> Back</HashLink>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title text-center pb-10">
                                <h3 className="title"><FaGasPump style={{ color: 'green' }} /> Concerned about fuel theft? </h3>
                                <hr className="hr hr-blurry" style={{ color: '#2B82D4' }} />
                            </div>
                            <div className="section-title pb-10">
                                <p>Here's a solution, using our high accuracy fuel/liquid sensors you can monitor fuel levels, usage and refills for your vehicle(s), fuel stations and other liquid storage equipments/facilities. Using either bluetooth or wired connection, fuel data is transmitted to Vesta servers via GPS trackers or over the air ready for you to view. </p>
                            </div>
                            <div className="section-title text-center pb-10">
                                <p></p><p></p>
                                <p>
                                    <figure className="figure">
                                        <img src="assets/images/probe.png" alt="FMB 125 fuel sensor" title="FMB 125 fuel sensor" />
                                        <figcaption className="figure-caption">FMB 125 fuel sensor (wireless)</figcaption>
                                    </figure>
                                    <figure className="figure">
                                        <img src="assets/images/probe_3.png" alt="Hydrostatic pressure liquid level sensor" title="Hydrostatic pressure liquid level sensor" />
                                        <figcaption className="figure-caption">Hydrostatic liquid level sensor</figcaption>
                                    </figure>
                                </p>

                            </div>
                        </div>
                    </div>{/*  row  */}

                    <div className="row" >
                        <div className="col-md-12">


                            <div className="contact-wrapper form-style-two pt-30">
                                <h4 className="contact-title text-center pb-10"> <span>Below is what you get </span>
                                </h4>
                                <br />
                                <table className="table table-stripped">
                                    <tbody>
                                        <tr>
                                            <td>       <h1 className="contact-title text-left pb-10"><GiGasPump  style={{ color: 'green' }} />  <span>Report on fuel refills</span>
                                            </h1></td>
                                            <td> <h3 className="contact-title text-left pb-10"><TbReportSearch style={{ color: 'black' }} />  <span>Usage reports (text/graphs) </span>
                                            </h3></td>
                                        </tr>
                                        <td>       <h4 className="contact-title text-left pb-10"><MdNotificationsActive style={{ color: 'red' }} />  <span>  Notification when fuel is stolen </span>
                                            </h4></td>
                                            <td> <h4 className="contact-title text-left pb-10"><GiMoneyStack style={{ color: 'orange' }} />  <span>Derive fuel costs </span>
                                            </h4></td>
                                         
                                        <tr>
                                        <td> <h4 className="contact-title text-left pb-10"><FaRoute style={{ color: 'blue' }} />  <span>Fuel usage per route </span>
                                            </h4></td>
                                            <td> <h4 className="contact-title text-left pb-10"><TfiDashboard style={{ color: 'green' }} />  <span>Fuel level at any time </span>
                                            </h4></td>
                                        </tr>


                                    </tbody>
                                </table>
                                <p className="text text-left" style={{ color: '#000' }}><MdOutlineContactPhone style={{ color: 'green' }} /> <a href="#message">Send us a message</a> for further inquiries or you can <a href="https://tawk.to/chat/639a0baddaff0e1306dcad10/1gk8rr7id" target="_blank"> Chat Now <BsChatDots /> </a> with one of our team members.</p>
                         
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}
export default MonitorFuel;