import { HashLink } from "react-router-hash-link";

function Footer() {
    return (
        <section className="footer-area footer-dark">
    <div className="container">
        <div className="row justify-content-center">
            <div className="col-lg-6">
                <div className="footer-logo text-center">
                    <HashLink className="mt-30" to="/#home"><img src="assets/images/vesta_logo_final.svg" alt="Logo" /></HashLink>
                </div> {/*  footer logo  */}
                <ul className="social text-center mt-60">
                    <li><a
                            href="https://api.whatsapp.com/send?phone=255757141663&text=Thanks%20for%20reaching%20out%20to%20us%20(Vesta%20NextGen%20Ltd)%20via%20this%20platform,%20we%20will%20get%20back%20to%20you%20shortly."><i
                                className="lni lni-whatsapp"></i></a></li>
                    <li><a href="https://twitter.com/VestaNextGen/"><i className="lni lni-twitter-original"></i></a>
                    </li>
                    <li><a href="https://www.instagram.com/vestanextgen/"><i
                                className="lni lni-instagram-original"></i></a></li>
                    <li><a href="https://www.linkedin.com/company/vesta-nextgen-limited/"><i
                                className="lni lni-linkedin-original"></i></a></li>
                </ul> {/*  social  */}
                <div className="footer-support text-center">
                    <span className="number">+255 757 141 663</span>
                    <span className="mail">info@vestatz.com</span>
                </div>
                <div className="copyright text-center mt-35">
                    <p className="text">Designed by <a href="https://vestatz.com" rel="nofollow"
                            style={{color: "white"}}>Vesta NextGen</a> , &copy; All
                        rights reserved 2022.</p>
                </div> {/*   copyright  */}
            </div>
        </div> {/*  row  */}
    </div> {/*  container  */}
</section>
    );
}
export default Footer;