import { AiFillContainer, AiOutlineDesktop } from "react-icons/ai";
import { BsBuilding, BsChatDots } from "react-icons/bs";
import { FaCar } from "react-icons/fa";
import { GiFarmTractor, GiRollingSuitcase } from "react-icons/gi";
import { MdArrowBackIos, MdChair, MdOutlineContactPhone } from "react-icons/md";
import { HashLink } from "react-router-hash-link";

function Asset() {
    return (
        <div>

            <section id="asset" className="features-area">
                <div className="container">
                    <HashLink to="/home#services" ><h1><MdArrowBackIos style={{ color: '#2B82D4' }} /> </h1> Back</HashLink>
                    <div className="row justify-content-center">
                        <div className="col-lg-12 col-md-10">
                            <div className="section-title text-center pb-10">
                                <h3 className="title"><GiRollingSuitcase style={{fontSize: '1.5em', color: '#2B82D4'}} />  Asset tracking</h3>
                                <hr className="hr hr-blurry" style={{ color: '#2B82D4' }} />
                                <p className="text"  style={{ color: '#000' }}>We have a variety of portable GPS devices that can track assets like personal belongings, equipments, raw materials, products etc. Up to 90 days of battery life before recharging.</p>
                                <div className="section-title text-center pb-10">
                                    <p></p><p></p>
                                    <p></p><p></p>
                                    <p> <img src="assets/images/topin1.png" /> <img src="assets/images/topin2.png" /><img src="assets/images/topin3.png" /> </p>
                                </div>
                                <div className="section-title text-center pb-10">
                                    <p></p><p></p>
                                    <p></p><p></p>
                                    <p className="text text-center" style={{ color: '#000' }}>You can track things like: </p>
                                <div>

                                    <ul className="text " >
                                        <li><h5><GiRollingSuitcase /> Suitcases</h5></li>
                                        <li><h4><AiFillContainer /> Shipping containers </h4></li>
                                        <li><h3><FaCar /> Hide in personal vehicles</h3></li>
                                        <li><h2><AiOutlineDesktop /> TVs, computers and other electronics</h2></li>
                                        <li><h3><GiFarmTractor /> Farming equipments like tractors</h3></li>
                                        <li><h4><BsBuilding /> Construction equipments</h4></li>
                                        <li><h5><MdChair /> Furnitures</h5></li>
                                    </ul>
                                </div>
                                <p className="text text-center" style={{ color: '#000' }}><MdOutlineContactPhone style={{ color: 'green' }} /> <a href="#message">Send us a message</a> for further inquiries or you can <a href="https://tawk.to/chat/639a0baddaff0e1306dcad10/1gk8rr7id" target="_blank"> Chat Now <BsChatDots /> </a> with one of our team members.</p>
                         
                                </div>
                            </div> {/*  row  */}
                        </div>
                    </div> {/*  row  */}
                </div>
            </section>

        </div>
    );
}
export default Asset;