import { HashLink } from "react-router-hash-link";
import { TbEngine, TbEngineOff, TbSatellite, TbMap2, TbVectorTriangleOff, TbMountain, TbPlugConnected, TbPlugConnectedX, TbReportAnalytics } from 'react-icons/tb';
import { GiNetworkBars, GiCarBattery, GiPositionMarker, GiSpeedometer, GiLaserBurst, GiPathDistance } from 'react-icons/gi';
import { MdOutlineSpeed, MdNotificationsActive, MdElectricCar, MdArrowBackIos } from 'react-icons/md';
import { BsSpeedometer, BsClockHistory, BsFillStoplightsFill, BsGraphUp } from 'react-icons/bs';
import { GrStatusInfo, GrMap } from 'react-icons/gr';
import { FcMiddleBattery, FcLowBattery, FcElectricalSensor } from 'react-icons/fc';
import { BiTime } from 'react-icons/bi';
import { SiBaremetrics } from 'react-icons/si';
import { FaTruckMoving, FaRoute, FaTemperatureLow, FaBatteryHalf } from 'react-icons/fa';
import { AiOutlineArrowRight, AiOutlineArrowLeft } from 'react-icons/ai';
import { MdDriveEta } from 'react-icons/md';

function Fleet() {
    return (
        <section id="fleet" className="fleet-area">
            <div className="container">
                <HashLink to="/home#services" ><h1><MdArrowBackIos style={{ color: '#2B82D4' }} /> </h1> Back</HashLink>
                <div className="row">
                    <div className="col-md-12">
                        <div className="section-title text-center pb-10">
                            <h3 className="title"><i className="lni lni-car" style={{ color: '#2B82D4' }}></i> Monitor your fleet with Vesta tracking </h3>
                            <hr className="hr hr-blurry" style={{ color: '#2B82D4' }} />
                        </div>
                        <div className="section-title pb-10">
                            <p>Using state of the art tracking devices we will guarantee real time tracking of all your vehicles. Location data is collected at a configurable interval and is pushed to Vesta servers where our customers can access and monitor their vehicles using our mobile apps and web platforms. <a className="page-scroll" href="#message">Contact us now!</a> and place your order.  </p>
                        </div>
                        <div className="section-title text-center pb-10">
                            <p></p><p></p>
                            <p> <img src="assets/images/mv720.png" alt="FMB 125" /> <img src="assets/images/fmb125.png" alt="FMB 125" /> </p>
                        </div>
                    </div>
                </div>{/*  row  */}
                <div className="row" >
                    <div className="col-md-12">


                        <div className="contact-wrapper form-style-two pt-30">
                            <h4 className="contact-title text-center pb-10"><MdNotificationsActive style={{ color: 'red' }} /> Get notified <span>via Website (desktop), E-mail, SMS and Push</span>
                            </h4>
                            <br />
                            <table class="table table-striped">
                                <tbody>
                                    <tr>
                                        <td><TbEngine style={{ color: 'green' }} /> Ignition ON</td>
                                        <td><TbEngineOff style={{ color: 'grey' }} /> Ignition OFF</td>
                                        <td><GiSpeedometer style={{ color: 'red' }} /> Overspeed</td>
                                    </tr>
                                    <tr>
                                        <td><GiLaserBurst /><FaTruckMoving /> Harsh acceleration</td>
                                        <td><GiLaserBurst /><FaTruckMoving /> Harsh braking</td>
                                        <td><FaTruckMoving /> Moving</td>
                                    </tr>
                                    <tr>
                                        <td><TbPlugConnectedX /> Battery connected</td>
                                        <td><TbPlugConnected /> Battery disconnected</td>
                                        <td><FaTruckMoving /> Idling</td>
                                    </tr>
                                    <tr>
                                    <td><AiOutlineArrowRight /> Zone in</td>
                                        <td><AiOutlineArrowLeft /> Zone out</td>
                                        <td><FcLowBattery /> Low battery</td>
                                    </tr>
                                    <tr>
                                    <td><MdElectricCar /> Harsh cornering</td>
                                        <td><FcElectricalSensor /> Shock</td>
                                        <td><BsFillStoplightsFill style={{ color: 'red' }} /> Stopped</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                        <div className="contact-wrapper form-style-two pt-50">
                            <h4 className="contact-title text-center pb-10"><SiBaremetrics style={{ color: '#2B82D4' }} /> <span>Some of key</span> Metrics <span> to monitor your objects</span>
                            </h4>

                            <br />

                            <table class="table table-striped">
                                <tbody>
                                    <tr>
                                        
                                        <td><BiTime /> Time</td>
                                        <td><TbVectorTriangleOff /> Angle</td>
                                        <td><GiPositionMarker /> Position</td>
                                       
                                    </tr>
                                    <tr>
                                        <td><GrMap /> Nearest zone</td>
                                        <td><MdOutlineSpeed style={{ color: 'black' }} /> Speed</td>                    
                                        <td><BsSpeedometer style={{ color: 'black' }} /> Odometer</td>
                                        
                                    </tr>
                                    <tr>
                                        <td><TbMountain /> Altitude</td>
                                        <td><FcMiddleBattery /> Battery voltage</td>
                                        <td><GiNetworkBars style={{ color: 'blue' }} /> GSM level</td>
                                        
                                    </tr>
                                    <tr>
                                        <td><TbSatellite /> GPS signal</td>
                                        <td><FaBatteryHalf /> Device battery</td>
                                        <td><TbEngine style={{ color: 'green' }} /> Ignition status</td>
                                    </tr>
                                    <tr>
                                    <td><BsClockHistory /> Engine Hours</td>
                                    <td><GiCarBattery /> Battery status</td>
                                    <td><GrStatusInfo /> Engine status</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                        <div className="contact-wrapper form-style-two pt-50">
                            <h4 className="contact-title text-center pb-10"><TbReportAnalytics style={{ color: '#2B82D4' }} /> <span>A variety of </span>  Reports <span> available at your disposal</span>
                            </h4>

                            <br />


                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">General reports</th>
                                        <th scope="col">Graphical reports</th>
                                        <th scope="col">Map reports</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><TbReportAnalytics /> General information</td>
                                        <td><TbMountain /> Altitude</td>
                                        <td><FaRoute /> Routes</td>
                                    </tr>
                                    <tr>
                                        <td><GiPathDistance /> Milleage (daily)</td>
                                        <td><MdOutlineSpeed /> Speed</td>
                                        <td><FaRoute style={{ color: 'blue' }} /> Routes with stops</td>
                                    </tr>
                                    <tr>
                                        <td><GiPositionMarker /> Current position</td>
                                        <td><TbEngine /> Ignition</td>
                                    </tr>
                                    <tr>
                                        <td><MdDriveEta /> Drives and stops</td>
                                        <td><FaTemperatureLow style={{ color: 'red' }} /> Temperature</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                    </div>
                </div>
            </div> {/*  row  */}


        </section>
    );
}
export default Fleet;