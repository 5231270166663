import { MdArrowBackIos, MdOutlineContactPhone } from "react-icons/md";
import { HashLink } from "react-router-hash-link";
import './css/CloudSolution.css';
import { BsChatDots } from "react-icons/bs";
import {FiPhoneOutgoing} from "react-icons/fi";
import { FaChalkboardTeacher, FaFileContract, FaFileSignature, FaHandshake } from "react-icons/fa";
import { AiOutlineSolution } from "react-icons/ai";
import { HiBuildingOffice } from "react-icons/hi2";
import { IoConstructOutline } from "react-icons/io5";
import { FcCustomerSupport } from "react-icons/fc";

function CloudSolution() {
    return (
        <div>

            <section id="cloud" className="features-area">
                <div className="container">
                    <HashLink to="/home#services" ><h1><MdArrowBackIos style={{ color: '#2B82D4' }} /> </h1> Back</HashLink>
                    <div className="row justify-content-center">
                        <div className="col-lg-12 col-md-10">
                            <div className="section-title text-center pb-10">
                                <h3 className="title"><i className="lni lni-cloud-network" style={{ color: '#2B82D4' }}></i> Cloud solutions</h3>
                                <hr className="hr hr-blurry" style={{ color: '#2B82D4' }} />
                                <p className="text" style={{ color: 'black' }}> Vesta offers cloud based solutions across most business domains in the realms of custom software, API, IoT, cloud migration, machine learning and artificial intelligence. We have software experts with experience in modern public, private and hybrid cloud platforms and technologies such as <b>AWS</b>, <b>Azure</b>, <b>Google Cloud</b>, <b>CloudFoundry</b>, <b>Mulesoft</b> etc. capable of delivering robust solutions with efficiency, performance, quality and in a cost effective manner. </p>
                            </div> {/*  row  */}
                        </div>
                    </div> {/*  row  */}

                    <div className="row">


                        <div className="container my-5">
                            <div className="row">
                                <div className="col-md-6 offset-md-3">
                                    <h4 style={{ marginLeft: '1.2rem' }}>Below are steps and events of how it all works: </h4>
                                    <ul className="timeline-3" style={{padding: '28px'}}>
                                        <li>
                                            <p><h3 style={{ color: '#2B82D4' }}><FiPhoneOutgoing style={{ color: 'green' }} /> Contact us</h3></p>
                                            <p className="mt-2">Kindly reach out to us via email, social media, <a href="https://tawk.to/chat/639a0baddaff0e1306dcad10/1gk8rr7id" target="_blank"> <BsChatDots /> live chat</a>  or our contact form <a href="#message">here</a>.</p>
                                        </li>
                                        <li>
                                            <p><h3 style={{ color: '#2B82D4' }}><FaHandshake /> Meet and greet</h3></p>
                                            <p className="mt-2">Here we usually like to meet up for a cup of coffee to understand your line of business (LOB) and what you're looking for. Not possible? We can always get on a conference call or even via email.</p>
                                        </li>
                                        <li>
                                            <p><h3 style={{ color: '#2B82D4' }}><AiOutlineSolution /> Problem statement</h3></p>
                                            <p className="mt-2">With the help of our experts we will put down a formal blue print of what you would like us to do for you.</p>
                                        </li>
                                        <li>
                                            <p><h3 style={{ color: '#2B82D4' }}><HiBuildingOffice /> Site visit</h3></p>
                                            <p className="mt-2">To fully grasp the nature of your business and be able to meet your expectations we always advise inviting our team to your place of business for a quick visit. </p>
                                        </li>
                                        <li>
                                            <p><h3 style={{ color: '#2B82D4' }}><AiOutlineSolution />Proposed solution</h3></p>
                                            <p className="mt-2">We will propose a formal solution based on our findings above.</p>
                                        </li>
                                        <li>
                                            <p><h3 style={{ color: '#2B82D4' }}><FaFileContract /> Terms and agreement</h3></p>
                                            <p className="mt-2">Here we agree on solution approach, timelines, delivery strategy, charges and payment terms. </p>
                                        </li>
                                        <li>
                                            <p><h3 style={{ color: '#2B82D4' }}><FaFileSignature /> Prepare and sign contract</h3></p>
                                            <p className="mt-2">We sign a contract based on terms and agreements above.</p>
                                        </li>
                                        <li>
                                            <p><h3 style={{ color: '#2B82D4' }}><IoConstructOutline /> Implementation</h3></p>
                                            <p className="mt-2">Our team of experts gets to work and make things happen. This might involve frequent communications and even demos as the work continues. </p>
                                        </li>
                                        <li>
                                            <p><h3 style={{ color: '#2B82D4' }}><FaChalkboardTeacher /> Deliver and train</h3></p>
                                            <p className="mt-2">After we complete your work we will train your staff and everyone of interest to ensure complete understanding of the solution/product.</p>
                                        </li>
                                        <li>
                                            <p><h3 style={{ color: '#2B82D4' }}><FcCustomerSupport /> Support and maintenance</h3></p>
                                            <p className="mt-2">We always give a grace period (based on terms) to give your staff all the support they need to seamlessly  operate your final product.  </p>
                                        </li>
                                    </ul>
                                </div>
                         
                            </div>
                            
                        </div>

                    </div>
                    <p className="text text-center" style={{ color: '#000' }}><MdOutlineContactPhone style={{ color: 'green' }} /> <a href="#message">Send us a message</a> for further inquiries or you can <a href="https://tawk.to/chat/639a0baddaff0e1306dcad10/1gk8rr7id" target="_blank"> Chat Now <BsChatDots /> </a> with one of our team members.</p>
                         
                </div>
            </section>


        </div>
    );
}
export default CloudSolution;