import { HashLink } from "react-router-hash-link";
import { MdArrowBackIos, MdOutlineContactPhone } from "react-icons/md";
import { BsChatDots, BsFillInfoSquareFill } from "react-icons/bs";
import { FcInfo } from "react-icons/fc";

function ApiAsAServiceBYOD() {
    return (
        <div>

            <section id="api" className="features-area">
                <div className="container">
                <HashLink to="/home#services" ><h1><MdArrowBackIos style={{ color: '#2B82D4' }} /> </h1> Back</HashLink>
                    <div className="row justify-content-center">
                        <div className="col-md-12 col-md-10">
                            <div className="section-title text-center pb-10">
                                <h3 className="title"><i className="lni lni-code-alt" style={{color: '#2B82D4'}}></i> Bring your own device (BYOD) or just use our APIs</h3>
                                <hr className="hr hr-blurry" style={{ color: '#2B82D4' }} />
                                <p className="text text-left" style={{ color: '#000' }} ><b><FcInfo /> Bring your own device (BYOD):</b> Bring your own GPS tracker(s) then use our mobile apps and platforms to get real time tracking of your objects. We will configure your devices to send location data to our servers. </p>
                                <p className="text text-left" style={{ color: '#000' }} >Our systems support over 250 GPS device protocols which cover most GPS devices on the market today. If your device is using a protocol that we currently don't support then our Engineers will implement this protocol to allow your devices to communicate with our servers.  </p>
                                <p className="text text-left" style={{ color: '#000' }}><b><FcInfo /> API as a Service: </b> Using either our own tracking device(s) or yours, we can provide fast, highly scalable APIs that you can use to integrate real time tracking into your systems and applications. If you're in the software industry and need real time tracking for your softwares our team will provide your developers solid API documentation and support they need to successully integrate real time tracking into your systems. We charge APIs based on usage and traffic patterns.  </p>
                                <p className="text text-left" style={{ color: '#000' }}><b> Example use case for API as a Service:</b> You own a restaurant and you have a mobile app (or you want one) where your customers can see real time location of delivery drivers as they deliver their food. As a business owner you don't have to worry about technical details of tracking software instead you're solely focused on improving quality of your services and business in general.</p>
                              
                              <p><img src="assets/images/byod_ill.png" alt="BYOD dellivery use case" title="BYOD delivery use case" /></p>
                                 <p className="text text-left" style={{ color: '#000' }}><MdOutlineContactPhone style={{ color: 'green' }} /> <a href="#message">Send us a message</a> for further inquiries or you can <a href="https://tawk.to/chat/639a0baddaff0e1306dcad10/1gk8rr7id" target="_blank"> Chat Now <BsChatDots /> </a> with one of our team members.</p>
                            </div> {/*  row  */}
                        </div>
                    </div> {/*  row  */}
                </div>
            </section>
        </div>
    );
}
export default ApiAsAServiceBYOD;
