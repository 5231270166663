
function Testimonial() {
    return (
        <div className="container">
            <div className="row justify-content-between">
                <div className="col-xl-5 col-lg-6">
                    <div className="testimonial-left-content mt-45">
                        <h6 className="sub-title">Testimonials</h6>
                        <h4 className="title">What Our Clients Says <br /> About Vesta</h4>
                        <ul className="testimonial-line">
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                        <p className="text">As we always strive to meet our clients expectations, some were kind enough to complement our team based on the level of satisfaction with our services and professionalism. <br /> <br /> Here are some of their comments using their own words.</p>
                    </div> {/*  testimonial left content  */}
                </div>
                <div className="col-lg-6">
                    <div className="testimonial-right-content mt-50">
                        <div className="quota">
                            <i className="lni lni-quotation"></i>
                        </div>
                        <div className="testimonial-content-wrapper testimonial-active">
                            <div className="single-testimonial">
                                <div className="testimonial-text">
                                    <p className="text">“Vesta's fuel monitoring system has been a life saver for our company. We were able to increase revenue that was lost due to mishandling fuel fillings and usage.”</p>
                                </div>
                                <div className="testimonial-author d-sm-flex justify-content-between">
                                    <div className="author-info d-flex align-items-center">
                                        <div className="author-image">
                                            <i className="lni lni-user" style={{ color: "#2B82D4" }}></i>
                                        </div>
                                        <div className="author-name media-body">
                                            <h5 className="name">Mr. Abdallah</h5>
                                            <span className="sub-title">Manager, Jambo&Sons</span>
                                        </div>
                                    </div>
                                    <div className="author-review">
                                        <ul className="star">
                                            <li><i className="lni lni-star-filled"></i></li>
                                            <li><i className="lni lni-star-filled"></i></li>
                                            <li><i className="lni lni-star-filled"></i></li>
                                            <li><i className="lni lni-star-filled"></i></li>
                                            <li><i className="lni lni-star-filled"></i></li>
                                        </ul>
                                        <span className="review">( 2 Reviews )</span>
                                    </div>
                                </div>
                            </div> {/*  single testimonial  */}
                            <div className="single-testimonial">
                                <div className="testimonial-text">
                                    <p className="text">“These guys are the real deal. They have been very quick and proactive in resolving issues with our vehicles whether it's onsite or enroute.”</p>
                                </div>
                                <div className="testimonial-author d-sm-flex justify-content-between">
                                    <div className="author-info d-flex align-items-center">
                                        <div className="author-image">
                                            <i className="lni lni-user" style={{ color: "#2B82D4" }}></i>
                                        </div>
                                        <div className="author-name media-body">
                                            <h5 className="name">Mr. Msoka</h5>
                                            <span className="sub-title">Site (Project) Manager, JVSPEK</span>
                                        </div>
                                    </div>
                                    <div className="author-review">
                                        <ul className="star">
                                            <li><i className="lni lni-star-filled"></i></li>
                                            <li><i className="lni lni-star-filled"></i></li>
                                            <li><i className="lni lni-star-filled"></i></li>
                                            <li><i className="lni lni-star-filled"></i></li>
                                            <li><i className="lni lni-star-filled"></i></li>
                                        </ul>
                                        <span className="review">( 2 Reviews )</span>
                                    </div>
                                </div>
                            </div> {/*  single testimonial  */}
                        </div> {/*  testimonial content wrapper  */}
                    </div> {/*  testimonial right content  */}
                </div>
            </div> {/*  row  */}
        </div>
    );
}
export default Testimonial;