import { HashLink } from "react-router-hash-link";

function Pricing() {
    return (
        <div className="container">
        <div className="row justify-content-center">
            <div className="col-lg-12 col-md-10">
                <div className="section-title text-center pb-25">
                    <h3 className="title">Pricing Plans</h3>
                    <p className="text">Being the cheapest pricing plans yet most robust services in the market, this will give you a glimpse of how affordable our servies are!</p>
                </div> {/*  section title  */}
            </div>
        </div> {/*  row  */}
        <div className="row justify-content-center">
            <div className="col-lg-4 col-md-7 col-sm-9">
                <div className="pricing-style mt-30">
                    <div className="pricing-icon text-center">
                        <img src="assets/images/basic.png" alt="" />
                    </div>
                    <div className="pricing-header text-center">
                        <h5 className="sub-title">Basic</h5>
                        <p className="month"><span className="price">TZS 30K</span>/month</p>
                    </div>
                    <div className="pricing-list">
                        <ul>
                            <li><i className="lni lni-check-mark-circle"></i> Basic tracking (rental)</li>
                            <li><i className="lni lni-check-mark-circle"></i> Up to 500 SMS Notifications</li>
                            <li><i className="lni lni-check-mark-circle"></i> Pay for 3 months   </li>
                            <li><i className="lni lni-check-mark-circle"></i>  </li>
                        </ul>
                    </div>
                    <div className="pricing-btn rounded-buttons text-center">
                        <a className="main-btn rounded-one" href="#message">GET STARTED</a>
                    </div>
                </div> {/*  pricing style one  */}
            </div>

            <div className="col-lg-4 col-md-7 col-sm-9">
                <div className="pricing-style mt-30">
                    <div className="pricing-icon text-center">
                        <img src="assets/images/pro.png" alt="" />
                    </div>
                    <div className="pricing-header text-center">
                        <h5 className="sub-title">Pro</h5>
                        <p className="month"><span className="price">TZS 50K</span>/month</p>
                    </div>
                    <div className="pricing-list">
                        <ul>
                            <li><i className="lni lni-check-mark-circle"></i> Basic tracking (rental)</li>
                            <li><i className="lni lni-check-mark-circle"></i> Fuel Monitoring</li>
                            <li><i className="lni lni-check-mark-circle"></i> Up to 700 SMS Notifications</li>
                            <li><i className="lni lni-check-mark-circle"></i> Additional TZS 450K for fuel sensor</li>
                        </ul>
                    </div>
                    <div className="pricing-btn rounded-buttons text-center">
                        <a className="main-btn rounded-one" href="#message">GET STARTED</a>
                    </div>
                </div> {/*  pricing style one  */}
            </div>

            <div className="col-lg-4 col-md-7 col-sm-9">
                <div className="pricing-style mt-30">
                    <div className="pricing-icon text-center">
                        <img src="assets/images/byod.png" alt="" />
                    </div>
                    <div className="pricing-header text-center">
                        <h5 className="sub-title">BYOD</h5>
                        <p className="month"><span className="price">TZS 25K</span>/month</p>
                    </div>
                    <div className="pricing-list">
                        <ul>
                            <li><i className="lni lni-check-mark-circle"></i> Bring your own device (BYOD) </li>
                            <li><i className="lni lni-check-mark-circle"></i> Up to 500 SMS Notifications</li>
                            <li><i className="lni lni-check-mark-circle"></i> Additional TZS 100K installation fee</li>
                            <li><i className="lni lni-check-mark-circle"></i>  </li>
                        </ul>
                    </div>
                    <div className="pricing-btn rounded-buttons text-center">
                        <HashLink className="main-btn rounded-one" to="/api#api">GET STARTED</HashLink>
                    </div>
                </div> {/*  pricing style one  */}
            </div>
        </div> {/*  row  */}
    </div>  
    );
}
export default Pricing;