import { GiGasPump, GiRollingSuitcase } from "react-icons/gi";
import { HashLink } from "react-router-hash-link";

function Services() {
    return (
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-10">
              <div className="section-title text-center pb-10">
                <h3 className="title">Our Services</h3>
                <p className="text">Explore Vesta's service catalogue to discover what we offer in fleet management
                  and cloud solutions.</p>
              </div> {/*  row  */}
            </div>
          </div> {/*  row  */}
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-7 col-sm-9">
              <div className="single-features mt-40">
                <div className="features-title-icon d-flex justify-content-between">
                  <h4 className="features-title"><HashLink to="/fleet#fleet">Vehicle Tracking</HashLink></h4>
                  <div className="features-icon">
                    <i className="lni lni-car"></i>
                    <img className="shape" src="assets/images/f-shape-1.svg" alt="Shape" />
                  </div>
                </div>
                <div className="features-content">
                  <p className="text">Monitor speed, location, engine status, driving behavior, get notified by
                    push, web, email and SMS all in real time.</p>
                  <HashLink className="features-btn" to="/fleet#fleet">LEARN MORE</HashLink>
                </div>
              </div> {/*  single features  */}
            </div>
            <div className="col-lg-4 col-md-7 col-sm-9">
              <div className="single-features mt-40">
                <div className="features-title-icon d-flex justify-content-between">
                  <h4 className="features-title"><HashLink to="/fuel#fuel">Fuel Monitoring</HashLink></h4>
                  <div className="features-icon">
                  <GiGasPump style={{fontSize: '5.5em', color: '#2B82D4'}} />
                    <img className="shape" src="assets/images/f-shape-1.svg" alt="Shape" />
                  </div>
                </div>
                <div className="features-content">
                  <p className="text">Get real time fuel theft notifications. Generate all kinds of reports to
                    manage usage, cost, milleage etc.</p>
                  <HashLink className="features-btn" to="/fuel#fuel">LEARN MORE</HashLink>
                </div>
              </div> {/*  single features  */}
            </div>
            <div className="col-lg-4 col-md-7 col-sm-9">
              <div className="single-features mt-40">
                <div className="features-title-icon d-flex justify-content-between">
                  <h4 className="features-title"><HashLink to="/cloud#cloud">Cloud Solutions</HashLink></h4>
                  <div className="features-icon">
                    <i className="lni lni-cloud-network"></i>
                    <img className="shape" src="assets/images/f-shape-1.svg" alt="Shape" />
                  </div>
                </div>
                <div className="features-content">
                  <p className="text">We offer custom cloud based solutions in the realms of software, API, IoT
                    and artificial intelligence.</p>
                  <HashLink className="features-btn" to="/cloud#cloud">LEARN MORE</HashLink>
                </div>
              </div> {/*  single features  */}
            </div>
            <div className="col-lg-4 col-md-7 col-sm-9">
              <div className="single-features mt-40">
                <div className="features-title-icon d-flex justify-content-between">
                  <h4 className="features-title"><HashLink to="/api#api">API as a Service</HashLink></h4>
                  <div className="features-icon">
                    <i className="lni lni-code"></i>
                    <img className="shape" src="assets/images/f-shape-1.svg" alt="Shape" />
                  </div>
                </div>
                <div className="features-content">
                  <p className="text">Using either our own tracking device(s) or yours, we can provide fast, highly scalable APIs that you can use to integrate real time tracking into your systems and applications.</p>
                  <HashLink className="features-btn" to="/api#api">LEARN MORE</HashLink>
                </div>
              </div> {/*  single features  */}
            </div>
            <div className="col-lg-4 col-md-7 col-sm-9">
              <div className="single-features mt-40">
                <div className="features-title-icon d-flex justify-content-between">
                  <h4 className="features-title"><HashLink to="/asset#asset">Asset Tracking</HashLink></h4>
                  <div className="features-icon">
                  <GiRollingSuitcase style={{fontSize: '5.5em', color: '#2B82D4'}} />
                    <img className="shape" src="assets/images/f-shape-1.svg" alt="Shape" />
                  </div>
                </div>
                <div className="features-content">
                  <p className="text">We have a variety of portable GPS devices that can track assets like personal belongings, equipments, raw materials, products etc.</p>
                  <HashLink className="features-btn" to="/asset#asset">LEARN MORE</HashLink>
                </div>
              </div> {/*  single features  */}
            </div>
          </div> {/*  row  */}
        </div>
    );
}
export default Services;