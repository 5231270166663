
function Portfolio() {
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-12 col-md-10">
                    <div className="section-title text-center pb-10">
                        <h3 className="title">Featured Works</h3>
                        <p className="text">Here's a partial list of our esteemed customers and partners across different
                            business domains. We've also included pictures of our team out in action, enjoy! </p>
                    </div> {/*  section title  */}
                </div>
            </div> {/*  row  */}
            <div className="row">
                <div className="col-lg-3 col-md-3">
                    <div className="portfolio-menu text-center mt-50">
                        <ul>
                            <li data-filter="*" className="active">ALL WORK</li>
                            <li data-filter=".clients-4">CLIENTS</li>
                            <li data-filter=".fuel-4">FUEL/FLEET</li>
                            <li data-filter=".partners-4">PARTNERS</li>
                        </ul>
                    </div> {/*  portfolio menu  */}
                </div>
                <div className="col-lg-9 col-md-9">
                    <div className="row no-gutters grid mt-50">
                        <div className="col-lg-4 col-sm-6 fuel-4">
                            <div className="single-portfolio">
                                <div className="portfolio-image">
                                    <img src="assets/images/portfolio/17.png" alt="" />
                                    <div className="portfolio-overlay d-flex align-items-center justify-content-center">
                                        <div className="portfolio-content">
                                            <div className="portfolio-icon">
                                                <a className="image-popup" href="assets/images/portfolio/17.png"><i
                                                    className="lni lni-zoom-in"></i></a>
                                                <img src="assets/images/portfolio/shape.svg" alt="shape" className="shape" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> {/*  single portfolio  */}
                        </div>
                        <div className="col-lg-4 col-sm-6 partners-4">
                            <div className="single-portfolio">
                                <div className="portfolio-image">
                                    <img src="assets/images/portfolio/9.png" alt="" />
                                    <div className="portfolio-overlay d-flex align-items-center justify-content-center">
                                        <div className="portfolio-content">
                                            <div className="portfolio-icon">
                                                <a href="https://www.fmeter.ru/en/" target="_blank"><i className="lni lni-link"></i></a>
                                                <img src="assets/images/portfolio/shape.svg" alt="shape" className="shape" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> {/*  single portfolio  */}
                        </div>
                        <div className="col-lg-4 col-sm-6 partners-4">
                            <div className="single-portfolio">
                                <div className="portfolio-image">
                                    <img src="assets/images/portfolio/2.png" alt="" />
                                    <div className="portfolio-overlay d-flex align-items-center justify-content-center">
                                        <div className="portfolio-content">
                                            <div className="portfolio-icon">
                                                <a href="https://nextsms.co.tz" target="_blank"><i className="lni lni-link"></i></a>
                                                <img src="assets/images/portfolio/shape.svg" alt="shape" className="shape" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> {/*  single portfolio  */}
                        </div>
                        <div className="col-lg-4 col-sm-6 clients-4">
                            <div className="single-portfolio">
                                <div className="portfolio-image">
                                    <img src="assets/images/portfolio/3.png" alt="" />
                                    <div className="portfolio-overlay d-flex align-items-center justify-content-center">
                                        <div className="portfolio-content">
                                            <div className="portfolio-icon">
                                                <a href="#"><i className="lni lni-link"></i></a>
                                                <img src="assets/images/portfolio/shape.svg" alt="shape" className="shape" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> {/*  single portfolio  */}
                        </div>
                        <div className="col-lg-4 col-sm-6 fuel-4 fuel-4">
                            <div className="single-portfolio">
                                <div className="portfolio-image">
                                    <img src="assets/images/portfolio/1.png" alt="" />
                                    <div className="portfolio-overlay d-flex align-items-center justify-content-center">
                                        <div className="portfolio-content">
                                            <div className="portfolio-icon">
                                                <a className="image-popup" href="assets/images/portfolio/1.png"><i
                                                    className="lni lni-zoom-in"></i></a>
                                                <img src="assets/images/portfolio/shape.svg" alt="shape" className="shape" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> {/*  single portfolio  */}
                        </div>
                        <div className="col-lg-4 col-sm-6 partners-4">
                            <div className="single-portfolio">
                                <div className="portfolio-image">
                                    <img src="assets/images/portfolio/5.png" alt="" />
                                    <div className="portfolio-overlay d-flex align-items-center justify-content-center">
                                        <div className="portfolio-content">
                                            <div className="portfolio-icon">
                                                <a href="https://teltonika-networks.com" target="_blank"><i className="lni lni-link"></i></a>
                                                <img src="assets/images/portfolio/shape.svg" alt="shape" className="shape" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> {/*  single portfolio  */}
                        </div>
                        <div className="col-lg-4 col-sm-6 fuel-4">
                            <div className="single-portfolio">
                                <div className="portfolio-image">
                                    <img src="assets/images/portfolio/6.png" alt="" />
                                    <div className="portfolio-overlay d-flex align-items-center justify-content-center">
                                        <div className="portfolio-content">
                                            <div className="portfolio-icon">
                                                <a className="image-popup" href="assets/images/portfolio/6.png"><i
                                                    className="lni lni-zoom-in"></i></a>
                                                <img src="assets/images/portfolio/shape.svg" alt="shape" className="shape" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> {/*  single portfolio  */}
                        </div>
                        <div className="col-lg-4 col-sm-6 clients-4">
                            <div className="single-portfolio">
                                <div className="portfolio-image">
                                    <img src="assets/images/portfolio/7.png" alt="" />
                                    <div className="portfolio-overlay d-flex align-items-center justify-content-center">
                                        <div className="portfolio-content">
                                            <div className="portfolio-icon">
                                                <a href="https://mtlconsulting-tz.com/" target="_blank"><i className="lni lni-link"></i></a>
                                                <img src="assets/images/portfolio/shape.svg" alt="shape" className="shape" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> {/*  single portfolio  */}
                        </div>
                        <div className="col-lg-4 col-sm-6 clients-4">
                            <div className="single-portfolio">
                                <div className="portfolio-image">
                                    <img src="assets/images/portfolio/8.png" alt="" />
                                    <div className="portfolio-overlay d-flex align-items-center justify-content-center">
                                        <div className="portfolio-content">
                                            <div className="portfolio-icon">
                                                <a href="https://jvspek.co.tz" target="_blank"><i className="lni lni-link"></i></a>
                                                <img src="assets/images/portfolio/shape.svg" alt="shape" className="shape" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> {/*  single portfolio  */}
                        </div>
                        <div className="col-lg-4 col-sm-6 fuel-4">
                            <div className="single-portfolio">
                                <div className="portfolio-image">
                                    <img src="assets/images/portfolio/16.png" alt="" />
                                    <div className="portfolio-overlay d-flex align-items-center justify-content-center">
                                        <div className="portfolio-content">
                                            <div className="portfolio-icon">
                                                <a className="image-popup" href="assets/images/portfolio/16.png"><i
                                                    className="lni lni-zoom-in"></i></a>
                                                <img src="assets/images/portfolio/shape.svg" alt="shape" className="shape" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> {/*  single portfolio  */}
                        </div>
                        <div className="col-lg-4 col-sm-6 fuel-4">
                            <div className="single-portfolio">
                                <div className="portfolio-image">
                                    <img src="assets/images/portfolio/10.png" alt="" />
                                    <div className="portfolio-overlay d-flex align-items-center justify-content-center">
                                        <div className="portfolio-content">
                                            <div className="portfolio-icon">
                                                <a className="image-popup" href="assets/images/portfolio/10.png"><i
                                                    className="lni lni-zoom-in"></i></a>
                                                <img src="assets/images/portfolio/shape.svg" alt="shape" className="shape" />
                                            </div>
                                            <div className="portfolio-icon">
                                                <a href="#"><i className="lni lni-link"></i></a>
                                                <img src="assets/images/portfolio/shape.svg" alt="shape" className="shape" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> {/*  single portfolio  */}
                        </div>

                        <div className="col-lg-4 col-sm-6 fuel-4">
                            <div className="single-portfolio">
                                <div className="portfolio-image">
                                    <img src="assets/images/portfolio/4.png" alt="" />
                                    <div className="portfolio-overlay d-flex align-items-center justify-content-center">
                                        <div className="portfolio-content">
                                            <div className="portfolio-icon">
                                                <a className="image-popup" href="assets/images/portfolio/4.png"><i
                                                    className="lni lni-zoom-in"></i></a>
                                                <img src="assets/images/portfolio/shape.svg" alt="shape" className="shape" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> {/*  single portfolio  */}
                        </div>
                        <div className="col-lg-4 col-sm-6 partners-4">
                            <div className="single-portfolio">
                                <div className="portfolio-image">
                                    <img src="assets/images/portfolio/12.png" alt="" />
                                    <div className="portfolio-overlay d-flex align-items-center justify-content-center">
                                        <div className="portfolio-content">
                                            <div className="portfolio-icon">
                                                <a href="https://aws.amazon.com/" target="_blank"><i className="lni lni-link"></i></a>
                                                <img src="assets/images/portfolio/shape.svg" alt="shape" className="shape" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> {/*  single portfolio  */}
                        </div>
                        <div className="col-lg-4 col-sm-6 clients-4">
                            <div className="single-portfolio">
                                <div className="portfolio-image">
                                    <img src="assets/images/portfolio/14.png" alt="" />
                                    <div className="portfolio-overlay d-flex align-items-center justify-content-center">
                                        <div className="portfolio-content">
                                            <div className="portfolio-icon">
                                                <a href="https://jvspek.co.tz/pioneer-builders-limited/" target="_blank"><i className="lni lni-link"></i></a>
                                                <img src="assets/images/portfolio/shape.svg" alt="shape" className="shape" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> {/*  single portfolio  */}
                        </div>
                        <div className="col-lg-4 col-sm-6 clients-4">
                            <div className="single-portfolio">
                                <div className="portfolio-image">
                                    <img src="assets/images/portfolio/15.png" alt="" />
                                    <div className="portfolio-overlay d-flex align-items-center justify-content-center">
                                        <div className="portfolio-content">
                                            <div className="portfolio-icon">
                                                <a href="https://jvspek.co.tz/skyline-properties-limited/" target="_blank"><i className="lni lni-link"></i></a>
                                                <img src="assets/images/portfolio/shape.svg" alt="shape" className="shape" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> {/*  single portfolio  */}
                        </div>
                        <div className="col-lg-4 col-sm-6 partners-4">
                            <div className="single-portfolio">
                                <div className="portfolio-image">
                                    <img src="assets/images/portfolio/11.png" alt="" />
                                    <div className="portfolio-overlay d-flex align-items-center justify-content-center">
                                        <div className="portfolio-content">
                                            <div className="portfolio-icon">
                                                <a href="https://www.google.com/maps" target="_blank"><i className="lni lni-link"></i></a>
                                                <img src="assets/images/portfolio/shape.svg" alt="shape" className="shape" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> {/*  single portfolio  */}
                        </div>
                    </div> {/*  row  */}
                </div>
            </div> {/*  row  */}
        </div>
    );
}
export default Portfolio;