import About from "./About";
import Portfolio from "./Portfolio";
import Pricing from "./Pricing";
import Services from "./Services";
import Team from "./Team";
import Testimonial from "./Testimonial";



function Home() {
    return (
        <div>

            <section id="services" className="features-area"> <Services /></section>

            <section id="portfolio" className="portfolio-area portfolio-four pb-100"><Portfolio /></section>

            <section id="pricing" className="pricing-area "><Pricing /></section>

            <section id="about" className="about-area"><About /></section>

            <section id="testimonial" className="testimonial-area"> <Testimonial /></section>

            <section id="team" className="team-area pt-120 pb-130"> <Team /></section>


        </div>
    );
}
export default Home;