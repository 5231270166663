import Home from "./components/Home";
import './App.css';
import { HashLink } from "react-router-hash-link";
import Footer from "./components/Footer";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import MonitorFuel from "./components/MonitorFuel";
import CloudSolution from "./components/CloudSolution";
import Fleet from "./components/Fleet";
import Asset from "./components/Asset";
import { BsChatDots } from 'react-icons/bs';
import  {GiCheckMark} from 'react-icons/gi';
import {TfiFaceSad} from 'react-icons/tfi';
import ApiAsAServiceBYOD from "./components/ApiAsAServiceBYOD";
import React, { useState } from 'react';
import LoadingSpinner from "./components/LoadingSpinner";


function App() {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [info, setInfo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
   
  const requestOptions = {
    method: 'POST',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      name: name,
      email: email,
      message: message
    })
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      let res = await fetch("https://api.vesta.systems:8083/api/online/message", requestOptions);
      let resJson = await res.json();
      if (res.status === 200) {
        setName("");
        setEmail("");
        setMessage("");
        setIsLoading(false);
        setInfo(<p className="text text-center" style={{ color: 'green' }}><GiCheckMark  style={{fontSize: '1.5em'}} /> <b> SENT! WE WILL GET IN TOUCH!</b> </p>);
      } else {
        setIsLoading(false);
        console.log(resJson);
        setInfo(<p className="text text-center" style={{ color: 'red' }}><TfiFaceSad  style={{fontSize: '1.5em'}} /> <b> APOLOGIES AS WE ARE UNABLE TO RECEIVE THIS MESSAGE, PLEASE TRY AGAIN LATER!</b> </p>)
      }
    } catch (err) {
      setIsLoading(false);
      setInfo("");
      setInfo(<p className="text text-center" style={{ color: 'red' }}><TfiFaceSad  style={{fontSize: '1.5em'}} /> <b> APOLOGIES AS WE ARE UNABLE TO RECEIVE THIS MESSAGE, PLEASE TRY AGAIN LATER!</b> </p>)
      console.log(err);
    }
  };

  return (
    <Router>
      <section className="navbar-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav className="navbar navbar-expand-lg">

                <HashLink className="navbar-brand" to="/home#home">
                  <img src="assets/images/vesta_logo_final.svg" alt="Logo" />
                </HashLink>

                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTwo"
                  aria-controls="navbarTwo" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="toggler-icon"></span>
                  <span className="toggler-icon"></span>
                  <span className="toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse sub-menu-bar" id="navbarTwo">
                  <ul className="navbar-nav m-auto">
                    <li className="nav-item active"><HashLink className="page-scroll" to="/home#home">home</HashLink></li>
                    <li className="nav-item"><HashLink className="page-scroll" to="/home#services">Services</HashLink></li>
                    <li className="nav-item"><HashLink className="page-scroll" to="/home#portfolio">Portfolio</HashLink></li>
                    <li className="nav-item"><HashLink className="page-scroll" to="/home#pricing">Pricing</HashLink></li>
                    <li className="nav-item"><HashLink className="page-scroll" to="/home#about">About</HashLink></li>
                    <li className="nav-item"><HashLink className="page-scroll" to="/home#team">Team</HashLink></li>
                    <li className="nav-item"><HashLink className="page-scroll" to="/home#contact">Contact</HashLink></li>
                  </ul>
                </div>
                <div className="navbar-btn d-none d-sm-inline-block">
                  <ul>
                    <li><a className="solid" href="https://tracking.vestatz.com" target="_blank">TRACK
                    </a></li>
                  </ul>
                </div>


              </nav> {/*  navbar  */}
            </div>
          </div> {/*  row  */}
        </div>
      </section>

      <section id="home" className="slider-area">
        <div id="carouselThree" className="carousel slide" data-ride="carousel">
          <ol className="carousel-indicators">
            <li data-target="#carouselThree" data-slide-to="0" className="active"></li>
            <li data-target="#carouselThree" data-slide-to="1"></li>
            <li data-target="#carouselThree" data-slide-to="2"></li>
            <li data-target="#carouselThree" data-slide-to="3"></li>
          </ol>
          <div className="carousel-inner">

            <div className="carousel-item active">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="slider-content">
                      <h1 className="title">Monitor fuel usage </h1>
                      <p className="text">Get real time fuel theft notifications. Generate all kinds of
                        reports to manage usage, cost, milleage etc. </p>
                      <ul className="slider-btn rounded-buttons">
                        <li><HashLink to="/fuel#fuel" className="main-btn rounded-one page-scroll">GET STARTED</HashLink></li>
                      </ul>
                    </div> {/*  slider-content  */}
                  </div>
                </div> {/*  row  */}
              </div> {/*  container  */}
              <div className="slider-image-box d-none d-lg-flex align-items-end">
                <div className="slider-image">
                  <img src="assets/images/slider/2.png" alt="Monitor" />
                </div> {/*  slider-imgae  */}
              </div> {/*  slider-imgae box  */}
            </div> {/*  carousel-item  */}

            <div className="carousel-item">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="slider-content">
                      <h1 className="title">Track your fleet</h1>
                      <p className="text">Monitor speed, location, engine status, driving behavior, get
                        notified by push, web, email and SMS all in real time.</p>
                      <ul className="slider-btn rounded-buttons">
                        <li><HashLink className="main-btn rounded-one" to="/fleet#fleet">GET STARTED</HashLink></li>
                      </ul>
                    </div>
                  </div>
                </div> {/*  row  */}
              </div> {/*  container  */}
              <div className="slider-image-box d-none d-lg-flex align-items-end">
                <div className="slider-image">
                  <img src="assets/images/slider/1.png" alt="Track" />
                </div> {/*  slider-imgae  */}
              </div> {/*  slider-imgae box  */}
            </div> {/*  carousel-item  */}

            <div className="carousel-item">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="slider-content">
                      <h1 className="title">Cloud solutions</h1>
                      <p className="text">We offer custom cloud based solutions in the realms of software,
                        API, IoT and artificial intelligence.</p>
                      <ul className="slider-btn rounded-buttons">
                        <li><HashLink className="main-btn rounded-one" to="/cloud#cloud">GET STARTED</HashLink></li>
                      </ul>
                    </div> {/*  slider-content  */}
                  </div>
                </div> {/*  row  */}
              </div> {/*  container  */}
              <div className="slider-image-box d-none d-lg-flex align-items-end">
                <div className="slider-image">
                  <img src="assets/images/slider/3.png" alt="Cloud" />
                </div> {/*  slider-imgae  */}
              </div> {/*  slider-imgae box  */}
            </div> {/*  carousel-item  */}

            <div className="carousel-item">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="slider-content">
                      <h1 className="title">Download our app</h1>
                      <p className="text">Download our mobile app on App Store and Google Play Store to enjoy
                        a rich and friendly tracking experience</p>
                      <ul className="slider-btn rounded-buttons">
                        <li><a className="main-btn rounded-one" href="#">iOS</a></li>
                        <li><a className="main-btn rounded-one"
                          href="https://play.google.com/store/apps/details?id=com.vestatz.tracking" target="_blank">Android</a>
                        </li>
                      </ul>
                    </div> {/*  slider-content  */}
                  </div>
                </div> {/*  row  */}
              </div> {/*  container  */}
              <div className="slider-image-box d-none d-lg-flex align-items-end">
                <div className="slider-image">
                  <img src="assets/images/slider/4.png" alt="Mobile" />
                </div> {/*  slider-imgae  */}
              </div> {/*  slider-imgae box  */}
            </div> {/*  carousel-item  */}
          </div>

          <a className="carousel-control-prev" href="#carouselThree" role="button" data-slide="prev">
            <i className="lni lni-arrow-left"></i>
          </a>
          <a className="carousel-control-next" href="#carouselThree" role="button" data-slide="next">
            <i className="lni lni-arrow-right"></i>
          </a>
        </div>
      </section>

      <Routes>
        <Route exact path='/' element={< Home />}></Route>
        <Route exact path='/fuel' element={< MonitorFuel />}></Route>
        <Route exact path='/cloud' element={< CloudSolution />}></Route>
        <Route exact path='/fleet' element={< Fleet />}></Route>
        <Route exact path='/asset' element={< Asset />}></Route>
        <Route exact path='/api' element={< ApiAsAServiceBYOD />}></Route>
        <Route exact path='/home' element={< Home />}></Route>
      </Routes>


      <section id="contact" className="features-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-10">
              <div className="section-title text-center pb-30">
                <h3 className="title page-scroll" style={{ color: "#2B82D4" }}>Contact Us Now!</h3>
                <p className="text" style={{ color: '#000000' }}>Thank you for your interest in Vesta and our services. Feel free to send us a <HashLink to="/home#message">message below</HashLink>, or you can <a href="https://tawk.to/chat/639a0baddaff0e1306dcad10/1gk8rr7id" target="_blank"> Chat Now <BsChatDots /> </a> with one of our team members.
                </p>
                <p>
                  Also check us on social media platforms below. We look forward to hearing from you. Cheers!</p>
              <p className="text" style={{ color: '#000000' }}>
                  <a
                    href="https://api.whatsapp.com/send?phone=255757141663&text=Thanks%20for%20reaching%20out%20to%20us%20(Vesta%20NextGen%20Ltd)%20via%20this%20platform,%20we%20will%20get%20back%20to%20you%20shortly." target='_blank'>
                    <i className="lni lni-whatsapp" style={{ color: 'green', fontSize: '2.5em' }} ></i>
                  </a>&nbsp;
                  <a href="https://twitter.com/VestaNextGen/" target='_blank'><i className="lni lni-twitter-original" style={{fontSize: '2.5em'}}></i>
                  </a>&nbsp; 
                  <a href="https://www.instagram.com/vestanextgen/" target='_blank'>
                    <i className="lni lni-instagram-original" style={{ color: '#F56040', fontSize: '2.5em' }}></i>
                  </a>&nbsp;
                  <a href="https://www.linkedin.com/company/vesta-nextgen-limited/" target='_blank'>
                    <i className="lni lni-linkedin-original" style={{fontSize: '2.5em'}}></i>
                  </a>

                </p>  
              </div> {/*  section title  */}
            </div>
          </div> {/*  row  */}
          <div className="row">
            <div className="col-lg-12">
              <div className="contact-map mt-30">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1014937.4424740289!2d38.2182007338453!3d-6.445453371300183!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x185c4c16242ec833%3A0x615824c3ae3bf576!2sCRJE%20Victoria%20Noble%20Center!5e0!3m2!1sen!2sus!4v1671018514624!5m2!1sen!2sus"
                  width="600" height="450" style={{ border: 0 }} allowFullScreen="" loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"></iframe>
              </div> {/*  row  */}
            </div>
          </div> {/*  row  */}
          <div className="contact-info pt-30">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="single-contact-info contact-color-1 mt-30 d-flex ">
                  <div className="contact-info-icon">
                    <i className="lni lni-map-marker"></i>
                  </div>
                  <div className="contact-info-content media-body">
                    <p className="text"> Bagamoyo Road, Dar es salaam<br />14107 Tanzania.</p>
                  </div>
                </div> {/*  single contact info  */}
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-contact-info contact-color-2 mt-30 d-flex ">
                  <div className="contact-info-icon">
                    <i className="lni lni-envelope"></i>
                  </div>
                  <div className="contact-info-content media-body">
                    <p>Email us at: </p>
                    <p className="text">info@vestatz.com</p>
                  </div>
                </div> {/*  single contact info  */}
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-contact-info contact-color-3 mt-30 d-flex ">
                  <div className="contact-info-icon">
                    <i className="lni lni-phone"></i>
                  </div>
                  <div className="contact-info-content media-body">
                    <p>Call us at: </p>
                    <p className="text">+255 757 141 663</p>
                  </div>
                </div> {/*  single contact info  */}
              </div>
            </div> {/*  row  */}
          </div> {/*  contact info  */}
          <div id="message" className="row">
            <div className="col-lg-12">
              <div className="contact-wrapper form-style-two pt-115">
                <h4 className="contact-title pb-10"><i className="lni lni-envelope"></i> Leave <span>A Message.</span>
                </h4>

                <form id="contact-form needs-validation" onSubmit={handleSubmit} novalidate>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-input mt-25">
                        <label>Name</label>
                        <div className="input-items default">
                          <input name="name" type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} required />
                          <i className="lni lni-user"></i>
                        </div>
                      </div> {/*  form input  */}
                    </div>
                    <div className="col-md-6">
                      <div className="form-input mt-25">
                        <label>Email</label>
                        <div className="input-items default">
                          <input type="email" name="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                          <i className="lni lni-envelope"></i>
                        </div>
                      </div> {/*  form input  */}
                    </div>
                    <div className="col-md-12">
                      <div className="form-input mt-25">
                        <label>Massage</label>
                        <div className="input-items default">
                          <textarea name="massage" placeholder="Massage" value={message} onChange={(e) => setMessage(e.target.value)} required></textarea>
                          <i className="lni lni-pencil-alt"></i>
                        </div>
                      </div> {/*  form input  */}
                    </div>
                    <p className="form-message"></p>
                    <div className="col-md-12">
                      <div className="form-input light-rounded-buttons mt-30 btn-group">
                        <button className="main-btn light-rounded-two" type="submit" disabled={isLoading}>Send Message</button>
                        {isLoading ? <LoadingSpinner /> : info}
                        
                      </div> {/*  form input  */}
                    </div>
                  </div> {/*  row  */}
                </form>
              </div> {/*  contact wrapper form  */}
            </div>
          </div> {/*  row  */}
        </div>
      </section>

      <Footer />
    </Router>


  );
}

export default App;
